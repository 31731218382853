<template>
  <div class="text-center pa-5 pb-0 rowContentOdd">
    <HomepageSectionTitle
      i18nTitle="Welcome to Learn Gujarati"
    ></HomepageSectionTitle>

    <v-row class="justify-center no-gutters mb-1">
      <v-col xs="12" md="8" class="text-start mt-3 mt-md-5">
        <v-carousel
          cycle
          light
          :height="$vuetify.breakpoint.smAndUp ? 190 : 250"
          :show-arrows="false"
          :hide-delimiter-background="true"
        >
          <v-carousel-item>
            <p
              v-html="$i18n.messages['en']['dadaQuote']"
              class="gray--text"
              :class="$vuetify.breakpoint.mdAndUp ? 'quote' : 'text-body'"
              :style="!$vuetify.breakpoint.mdAndUp ? { fontSize: '14px' } : {}"
            ></p>
          </v-carousel-item>
          <v-carousel-item>
            <p
              v-html="$i18n.messages['hi']['dadaQuote']"
              class="gray--text"
              :class="$vuetify.breakpoint.mdAndUp ? 'quote' : 'text-body'"
            ></p>
          </v-carousel-item>
          <v-carousel-item>
            <p
              v-html="$i18n.messages['gu']['dadaQuote']"
              class="gray--text"
              :class="$vuetify.breakpoint.mdAndUp ? 'quote' : 'text-body'"
            ></p>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col xs="12" md="8" lg="7">
        <v-img alt="Learn Gujarati" src="../assets/dada.jpg"> </v-img>
      </v-col>
    </v-row>

    <a
      v-if="$vuetify.breakpoint.mdAndUp"
      class="ma-auto classRegistration shadowAnimatedButton pa-4"
      target="_blank"
      href="https://docs.google.com/forms/d/e/1FAIpQLSe5x6llFPNdCbeC05ta3OX85_QPqIL3UBFRwEN8MrQIUZzAcg/viewform?fbclid=IwAR1cH9sm3HGcY0adsF_i20V56laxpeyMPKsE-aFyZrtiBDaZIG9W_ce-JwA"
    >
      <!-- GUJARATI CLASS -->
      {{ $t('Register') }}
    </a>
    <v-row justify="center" no-gutters v-else>
      <a
        target="_blank"
        class="mt-4 mb-4 shadowAnimatedButton pa-2 pl-4 pr-4"
        href="https://docs.google.com/forms/d/e/1FAIpQLSe5x6llFPNdCbeC05ta3OX85_QPqIL3UBFRwEN8MrQIUZzAcg/viewform?fbclid=IwAR1cH9sm3HGcY0adsF_i20V56laxpeyMPKsE-aFyZrtiBDaZIG9W_ce-JwA"
      >
        {{ $t('Register') }}
      </a>
    </v-row>
  </div>
</template>

<script>
import HomepageSectionTitle from '../components/common/HomepageSectionTitle.vue'

export default {
  components: {
    HomepageSectionTitle
  }
}
</script>
<style scoped>
.quote {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 36px;
  color: var(--v-gray-darken4) !important;
}
.classRegistration {
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 2px solid;
  position: absolute;
  top: 5em;
  right: 0em;
  border-right: none;
  line-height: 1rem;
  text-decoration: none;
  width: 12em;
  padding-right: 1em;
  background-color: #ffffff;
}
</style>
