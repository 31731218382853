var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center pa-10 rowContentPattern"},[_c('div',{attrs:{"id":"fb-root"}}),_c('HomepageSectionTitle',{attrs:{"i18nTitle":"Why Learn Gujarati?"}}),_c('v-row',{staticClass:"justify-center",class:_vm.$vuetify.breakpoint.mdAndUp ? 'mt-10' : 'mt-3'},[_c('v-col',{staticClass:"pa-5",attrs:{"xs":"12","md":"6","cols":"12"}},[_c('v-row',{staticClass:"justify-center"},[(_vm.showCarousel)?_c('v-carousel',{attrs:{"id":"portfolioCarousel","cycle":"","height":_vm.$vuetify.breakpoint.smAndUp ? 450 : 160,"dark":"","hide-delimiters":""},on:{"change":_vm.onChange}},_vm._l((_vm.videos),function(v){return _c('v-carousel-item',{key:v.id,staticClass:"videoImage",attrs:{"src":'https://img.youtube.com/vi/' + v.id + '/maxresdefault.jpg'}},[_c('div',{staticClass:"centerIcon",style:(_vm.$vuetify.breakpoint.smAndUp
                  ? { margin: '-50px 0 0 -50px' }
                  : { margin: '-25px 0 0 -25px' })},[_c('v-img',{attrs:{"src":require("../assets/youtube-logo.png"),"width":_vm.$vuetify.breakpoint.smAndUp ? 100 : 50},on:{"click":function($event){return _vm.videoSelected(v.id)}}})],1)])}),1):_c('div',{style:({ width: '100%' })},[_c('Player',{key:'pl',ref:'playerRef',attrs:{"emit":['ended', 'ready', 'play', 'pause'],"media":{ type: 'youtube', playerId: _vm.currentVideoId },"options":{
              autoplay: true,
              displayDuration: true,
              noCookie: false,
              rel: 0,
              showinfo: 0,
              iv_load_policy: 3,
              modestbranding: 1,
              muted: false,
              settings: ['captions'],
              captions: { active: true }
            }},on:{"ended":_vm.videoEnded,"pause":_vm.videoPaused,"play":_vm.videoPlay,"ready":_vm.videoReady}})],1)],1),_c('v-row',[_c('div',{staticClass:"float-left mt-1"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.title))])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }