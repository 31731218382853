<template>
  <div class="home">
    <div>
      <ClassRegistration />
      <WhyLearnGujarati />
      <PracticePlay id="practice" />
      <StudyMaterialMainPage id="study-material" name="study-material" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ClassRegistration from '../components/ClassRegistration'
import WhyLearnGujarati from '../components/WhyLearnGujarati'
import PracticePlay from '../components/PracticePlay'
import StudyMaterialMainPage from '../components/StudyMaterialMainPage.vue'

export default {
  name: 'Home',
  components: {
    ClassRegistration,
    WhyLearnGujarati,
    PracticePlay,
    StudyMaterialMainPage
  },
  computed: {},
  mounted() {
    // Utils.dropConfetti(this.$confetti)
  }
}
</script>
<style>
.expansion-header {
  font-weight: bold;
}
.rowContentOdd {
  background-image: url('../assets/kakha-w.png'), url('../assets/kakha-w.png');
  background-repeat: repeat-y;
  background-position: left top, right top;
}
.rowContentEven {
  background-color: var(--v-primary-lighten6);
  background-image: url('../assets/kakha-r.png'), url('../assets/kakha-r.png');
  background-repeat: no-repeat, no-repeat;
  background-position: top left, top right;
}
.rowContentPattern {
  background: url('../assets/gplaypattern.png'),
    linear-gradient(
      252deg,
      rgba(176, 238, 214, 1) 2%,
      rgba(249, 249, 253, 1) 45%,
      rgba(171, 195, 231, 1) 93%
    );
  background-repeat: repeat;
  background-position: top left, top right;
  background-blend-mode: multiply;
}
@media only screen and (max-width: 1366px) {
  .rowContentEven,
  .rowContentOdd {
    background-image: none;
  }
}
</style>
