<template>
  <div class="text-center pa-5 pt-10 pb-0 rowContentPattern">
    <HomepageSectionTitle i18nTitle="study-material"></HomepageSectionTitle>
    <v-row justify="center" no-gutters align="center" class="pa-5">
      <v-img
        alt="Learn Gujarati"
        src="../assets/studymaterial.png"
        :max-width="$vuetify.breakpoint.mobile ? 200 : 400"
        :style="{ borderRadius: '10px' }"
      >
      </v-img>
    </v-row>

    <v-row justify="center" no-gutters align="center" class="pa-5">
      <router-link :to="'/study-material'" class="menuLink">
        <!-- <a target="_blank" :style="{ textDecoration: 'none' }" href="">
          <v-btn
            class="mt-4 mb-4 shadowAnimatedButton"
            rounded
            color="primary--text"
            :style="{ textDecoration: 'none', border: '1px solid' }"
          >
            {{ $t('study-material') }}
          </v-btn>
        </a> -->
        <a
          target="_blank"
          class="mt-4 mb-4 shadowAnimatedButton pa-2 pl-4 pr-4 text-uppercase"
          href=""
        >
          {{ $t('study-material') }}
        </a>
      </router-link>
    </v-row>
  </div>
</template>

<script>
import HomepageSectionTitle from '../components/common/HomepageSectionTitle.vue'

export default {
  components: {
    HomepageSectionTitle
  }
}
</script>
<style scoped>
.quote {
  font-size: 20px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 40px;
}
</style>
