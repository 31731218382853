<template>
  <div class="text-center pa-10 rowContentPattern">
    <div id="fb-root"></div>
    <HomepageSectionTitle
      i18nTitle="Why Learn Gujarati?"
    ></HomepageSectionTitle>
    <v-row
      class="justify-center"
      :class="$vuetify.breakpoint.mdAndUp ? 'mt-10' : 'mt-3'"
    >
      <v-col xs="12" md="6" cols="12" class="pa-5">
        <v-row class="justify-center">
          <v-carousel
            id="portfolioCarousel"
            cycle
            v-if="showCarousel"
            :height="$vuetify.breakpoint.smAndUp ? 450 : 160"
            dark
            hide-delimiters
            @change="onChange"
          >
            <v-carousel-item
              class="videoImage"
              v-for="v in videos"
              :key="v.id"
              :src="'https://img.youtube.com/vi/' + v.id + '/maxresdefault.jpg'"
            >
              <div
                class="centerIcon"
                :style="
                  $vuetify.breakpoint.smAndUp
                    ? { margin: '-50px 0 0 -50px' }
                    : { margin: '-25px 0 0 -25px' }
                "
              >
                <v-img
                  src="../assets/youtube-logo.png"
                  :width="$vuetify.breakpoint.smAndUp ? 100 : 50"
                  @click="videoSelected(v.id)"
                ></v-img>
              </div>
              <!-- <v-img ref="img" :src="'https://img.youtube.com/vi/'+v.id+'/maxresdefault.jpg'" width='100%' :aspect-ratio="16/9" class="videoImage">
                   <v-icon :size="$vuetify.breakpoint.mdAndUp?100:50" class="videoImageIcon"  color="primary" @click="videoSelected(v.id)">mdi-youtube</v-icon>
                  </v-img> -->
            </v-carousel-item>
          </v-carousel>
          <div v-else :style="{ width: '100%' }">
            <Player
              :ref="'playerRef'"
              :emit="['ended', 'ready', 'play', 'pause']"
              @ended="videoEnded"
              @pause="videoPaused"
              @play="videoPlay"
              @ready="videoReady"
              :media="{ type: 'youtube', playerId: currentVideoId }"
              :options="{
                autoplay: true,
                displayDuration: true,
                noCookie: false,
                rel: 0,
                showinfo: 0,
                iv_load_policy: 3,
                modestbranding: 1,
                muted: false,
                settings: ['captions'],
                captions: { active: true }
              }"
              :key="'pl'"
            ></Player>
          </div>
        </v-row>
        <v-row>
          <div class="float-left mt-1">
            <span class="primary--text">{{ title }}</span>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Player from './Player.vue'
import HomepageSectionTitle from './common/HomepageSectionTitle.vue'
export default {
  components: { Player, HomepageSectionTitle },
  data: () => ({
    title: '',
    videos: [
      { id: 'ln7XwbqHkXs', title: 'Why Learn Gujarati - Hindi' },
      { id: 'Pyjlx4YrcoA', title: 'Why Learn Gujarati - English' },
      { id: 'pkp-NOOCIzw', title: 'Gujarati Language Importance' }
    ],
    cycle: true,
    options: { hideControls: true, autoPlay: true },
    showCarousel: true,
    currentVideoId: 'ln7XwbqHkXs',
    showCarouselAfterPause: false
  }),
  computed: {
    player() {
      return this.$refs.playerRef.player
    }
  },
  methods: {
    videoReady() {
      this.player.play()
    },
    videoSelected(id) {
      this.currentVideoId = id
      this.showCarousel = false
    },

    videoEnded() {
      this.showCarousel = true
      this.currentVideoId = ''
      this.currentVideoId = 'ln7XwbqHkXs'
    },
    videoPaused() {
      this.showCarouselAfterPause = true
      setTimeout(() => {
        if (this.showCarouselAfterPause) {
          this.showCarousel = true
        }
      }, 5000)
    },
    videoPlay() {
      this.showCarouselAfterPause = false
    },
    onChange(number) {
      this.title = this.videos[number].title
    }
  }
}
</script>
<style scoped>
.videoImage /deep/ .v-image__image {
  border-radius: 15px !important;
}
.videoImage {
  text-align: center;
  border-radius: 15px;
}
.videoImageIcon {
  position: relative;
  top: calc(50% - 20px); /* 50% - 3/4 of icon height */
}
.centerIcon {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
