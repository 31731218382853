<template>
  <div class="text-center pa-5 practicePlay">
    <v-container fluid>
      <HomepageSectionTitle :i18nTitle="$t('practice')" />
      <v-row no-gutters class="ma-sm-5 ma-2 mt-5 mt-sm-10" justify="center">
        <v-col
          align="center"
          class="practice-play-box d-flex flex-column ma-1 ml-3 mr-3 ml-sm-5 mr-sm-5"
          cols="auto"
          v-for="category in $store.state.practicePlayCategories"
          :key="category.id"
        >
          <router-link :to="'/practice/' + category.id" class="menuLink">
            <v-row
              no-gutters
              justify="center"
              class="practice-play-box__imageBox"
            >
              <v-img
                :alt="category.name[$i18n.locale]"
                :src="imageUrl(category.image)"
                transition="scale-transition"
                class="practice-play-box__imageBox__image"
              />
            </v-row>
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="practice-play-box__practicePlayTitle pa-1 ma-1"
            >
              <span>
                {{ category.name[$i18n.locale] }}
              </span>
            </v-row>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HomepageSectionTitle from './common/HomepageSectionTitle.vue'
import Utils from '../util/Utils'
export default {
  data: () => ({
    expansionStatus: false,
    practicePlayExpansion: {}
  }),
  components: {
    HomepageSectionTitle
  },
  computed: {},
  methods: {
    imageUrl(assetPath) {
      return Utils.imgUrlByPath(`categories/${assetPath}`)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/global.scss';

$color-imgbox-1: #1e8394;
$color-imgbox-1-lighten: #dce8ea;

$color-imgbox-2: #581034;
$color-imgbox-2-lighten: #ebdfe5;

$color-imgbox-3: #766655;
$color-imgbox-3-lighten: #e9e4de;

$color-imgbox-4: #635576;
$color-imgbox-4-lighten: #e9e6ee;

@mixin theme-color($color, $lightenPercent) {
  border-color: $color;
  background-color: lighten($color, $lightenPercent);
}

.practice-play-box {
  transition: all 0.5s;

  &:hover {
    transform: translateY(-0.5rem);
  }

  //all direct child of link should have shadow
  & a > * {
    @include box-shadow-light;
  }

  &__imageBox {
    border-radius: 100%;
    border: 4px solid;
    @include theme-color($color-imgbox-1, 60%);
    height: 12rem;
    width: 12rem;
    transition: all 0.5s ease-out;

    &__image {
      margin: auto;
      scale: 0.7;
    }
    &:hover {
      transform: rotate(30deg);
    }
  }

  &__practicePlayTitle {
    color: white;
    position: relative;
    bottom: 1.8rem;
    height: 3.8rem;
    background-color: $color-imgbox-1;
    border-radius: 25px;
    font-family: 'Rancho';
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    padding: 5px;

    & span {
      width: 90%;
    }
  }

  &:nth-of-type(2n) &__imageBox {
    @include theme-color($color-imgbox-2, 75%);
  }
  &:nth-of-type(3n) &__imageBox {
    @include theme-color($color-imgbox-3, 50%);
  }
  &:nth-of-type(4n) &__imageBox {
    @include theme-color($color-imgbox-4, 50%);
  }

  &:nth-of-type(2n) a &__practicePlayTitle {
    background-color: $color-imgbox-2 !important;
  }
  &:nth-of-type(3n) a &__practicePlayTitle {
    background-color: $color-imgbox-3 !important;
  }
  &:nth-of-type(4n) a &__practicePlayTitle {
    background-color: $color-imgbox-4 !important;
  }
}
</style>
